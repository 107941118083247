import 'react-app-polyfill/ie11';

import React from 'react';
import App from './App';
import { render } from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';

import store from './redux/store';
import './services/i18n';

const rootElement = document.getElementById('root');

render(
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>,
  rootElement
);
