// TaxPayersList.js
import React, { useEffect, useState } from 'react';
import NeoTable from '../../elements/NeoLayouts/NeoTable';
import fetchData from '../../request/fetchData';
import { Info } from '@material-ui/icons';
import endpoints from '../../request/endpoints';

 const columnsList = [
  // { label: 'Année fiscale', id: 'fiscalYear' },
  // { label: 'Date de Déclaration', id: 'createdAt', format: (value) => formatDate(value) },
  // { label: 'Statut de Déclaration', id: 'status' },

  { label: 'Nom', id: 'entityBeneficiaryLastName' },
  { label: 'Prénom', id: 'entityBeneficiaryFirstName' },
  { label: 'Pays', id: 'entityBeneficiaryCountry' },
  { label: 'NIU', id: 'entityBeneficiaryNIU' },
   {
     sortable: false,
     width: 300,
     id: '_id',
     label: 'Actions',
     buttons: [
       {
         buttonColor: 'secondary',
         buttonLabel: 'detail',
         buttonIcon: <Info />,
         linkTo: 'detail', // here linkTo will trigger a button with router and id row passed as query params
         linkKey: 'entityBeneficiaryNIU'
       },
       {
         buttonColor: 'primary',
         buttonLabel: 'graph',
         buttonIcon: <Info />,
         linkTo: 'detailGraph', // here linkTo will trigger a button with router and id row passed as query params
         linkKey: 'entityBeneficiaryNIU'
       },
     ],
   },
  ];

function TaxPayersList() {
  const [items, setitems] = useState([]);

  useEffect(() => {
    // const queryParams = {status: 'submitted'};
    const queryParams = {};
    fetchData(endpoints.taxPayersList, queryParams).then((data) => setitems(data));
  }, []);

  if (!items || !items.length) {
    return null;
  }

  return (
      <NeoTable rows={items} columns={columnsList} />
  );
}

export default TaxPayersList;
