import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import T from 'prop-types';

import { setUser } from '../redux/reducers/sessionReducer';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import useMessage from '../elements/hooks/useMessage';

const useStyles = makeStyles((theme) => ({
  backgroundContainer: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    overflow: 'hidden',
    backgroundColor: '#c76b23',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    // filter: 'blur(42px)',
    // filter: 'blur(15px) opacity(95%) brightness(80%)',
    filter: 'blur(3px)',
  },
  paper: {
    position: 'relative',
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },

  '@keyframes typeReveal': {
    from: { width: 0 },
  },

  welcome: {
    fontSize: '1.3rem',
    // fontWeight: 300,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textAlign: 'center',
    animation: '$typeReveal 5s steps(60, end)',
  },
  avatar: {
    marginLeft: 15,
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.info.light,
    backgroundColor: 'transparent',
    width: theme.spacing(18),
    height: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  captcha: {
    marginTop: 15,
    width: '100%',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function FakeLogin({ logged, userType, setUserFn }) {
  const [errorDataKey, seterrorDataKey] = useState(null);
  const [errorDataText, seterrorDataText] = useState(null);
  const [captchaVal, setCaptcha] = useState(null);
  const [refreshKey, setrefreshKey] = useState(1);
  const [isLogged, setLogged] = useState(logged);
  const [showForgotPwdSection, setShowForgotPwdSection] = useState(false);
  const [setMessage] = useMessage();

  const [formFields, setFormFieldValue] = useState({
    email: '',
    password: '',
    hpId: '',
  });
  const classes = useStyles();

  async function handleSubmit(userTypeKey) {

    const res = userTypeKey === 'agent'
      ? {
        id : "61169494764b8d00219946cb",
        type: 'provider',
        subType: undefined,
        email: "contact@test.cm",
        name: "AGENT_DGI",
        allowedResources: ['declaration', 'taxPayers', 'ecosynthesis', 'rcbe'],
        entitiesRoles: [{
          entity: "61169475764b8d00219946ca",
          entityLabel: "STRUCTUR DGI-1",
          entityPerimeterType: "national",
          roles: ["consult_all_RCBE_declaration"]
        }]
      }
      : {

      };

    if (res) {
      setUserFn({
        payload: res,
      });
      setLogged(true);
    }
  }

  if (isLogged) {
    const redirectURL =localStorage.getItem('redirectURL');
    if (redirectURL) {
      window.location.replace(redirectURL)
      localStorage.removeItem("redirectURL");
    }
    else {
      window.location.replace('/sp/home');
    }
  }


  return (
    <>
      <div className={classes.backgroundContainer} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography className={classes.welcome} component="h1" variant="h5">
            Bienvenue
          </Typography>

            <Button
              type="button"
              onClick={() => handleSubmit('agent')}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login agent
            </Button>
        </Paper>
      </Container>
    </>
  );
}

FakeLogin.propTypes = {
  setUserFn: T.func.isRequired,
  logged: T.string,
  userType: T.string,
};

FakeLogin.defaultProps = {
  logged: null,
  userType: null,
};

const mapStateToProps = ({
  session: {
    userType,
    logged,
  },
}) => {
  return {
    userType,
    logged
  };
};

const mapDispatchToProps = {
  setUserFn: setUser,
  // showMessageFn: showMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FakeLogin);
