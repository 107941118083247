import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import T from 'prop-types';
import { connect } from 'react-redux';

const POPIN_TIMEOUT = 8000;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PopInMessage({message}) {
  const [isShown, setShow] = useState(false);

  useEffect(() => {
    if (message && message.content) {
      setShow(true); // start timeout here
      setTimeout(() => {setShow(false);}, message.timeout || POPIN_TIMEOUT);
    } else {
      setShow(false);
    }
  }, [message]);

  if (!message || !message.content) {
    return null;
  }

  let severityLevel = ['error', 'warning', 'info', 'success'].includes(message.messageType) ? message.messageType : 'warning';


  // sentry : if error message send a sentry track
  if (severityLevel === 'error' && message.isSystemError) {
    Sentry.captureException(message.systemError || message.content);
  }

  // UI choice : forcing error UI even for warning atm
  if (severityLevel === 'warning') {
    severityLevel = 'error';
  }

  return (<Snackbar
    // autoHideDuration={1000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    open={isShown}
    // message="I love snacks"
    // onClose={handleClose}
    key={'messageKey'}
  >
    <Alert severity={severityLevel}>{message.content}</Alert>
  </Snackbar>);
}

PopInMessage.propTypes = {
  message: T.object,
};

// add here the dispatcher so we can reset the message ??
const mapStateToProps = state => ({
  message: state.layoutConfig.message,
});

export default connect(mapStateToProps)(PopInMessage);
