import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import availableLocales from '../availableLocales';
import i18nConf from '../../../services/i18n';
import { connect } from 'react-redux';
import { changeLocale } from '../../../redux/reducers/layoutConfigReducer';
import T from 'prop-types';
import { put } from '../../../request';
import endpoints from '../../../request/endpoints';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';

function NeoLocaleChange({ showIcon, locale, handleChangeLocale, logged }) {
  const { i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState(locale || 'en'); // todo use redux here for init  suppress this useState totally !!

  // set redux locale here from i18n as it is first detection and no value is still used
  useEffect(() => {
    if (i18n.languages && !locale) {
      const langList = availableLocales.map(elem => (elem.localeKey));
      const foundAvailableLang = i18n.languages.find(langCandidate => (langList.includes(langCandidate)));

      setCurrentLocale(foundAvailableLang);
      handleChangeLocale(foundAvailableLang);
    }
  }, [i18n.language]);

  useEffect(() => {
    // if they differ it means it has been changed elsewhere for this user, we update..
    if (i18n.language !== locale && locale) {
      i18nConf.changeLanguage(locale);
      setCurrentLocale(locale);
    }
  }, [locale]);

  function handleLocaleChange(event) {
    const {value} = event.target;
    handleChangeLocale(value);

    if (logged) {
      // if the user is authentified we update the locale value for the user account
      put(endpoints.userModify, {locale: value});
    }
  }

  return (
    <span style={{display: 'flex'}}>
      {showIcon && <TranslateOutlinedIcon style={{margin: '5px 5px 0px 0px'}} />}
      <Select value={currentLocale} onChange={handleLocaleChange}>
        {availableLocales.map(localeItem => (
          <MenuItem value={localeItem.localeKey} key={localeItem.localeKey}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{localeItem.label}</div>
            </div>
          </MenuItem>
        ))}
        {(process.env.REACT_APP_ENV === 'inte' || process.env.REACT_APP_ENV === "development") &&
        <MenuItem value={'cimode'} >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>DEBUG</div>
          </div>
        </MenuItem>}

      </Select>
    </span>
  );
}


NeoLocaleChange.propTypes = {
  showIcon: T.bool,
  locale: T.string,
  logged: T.string,
  handleChangeLocale: T.func.isRequired,
};

NeoLocaleChange.defaultProps = {
  showIcon: false,
  locale: null,
  logged: null,
};

const mapStateToProps = ({
  session: {
    logged
  },
  layoutConfig: {
    locale,
  },
}) => {
  return {
    locale,
    logged
  };
};

const mapDispatchToProps = dispatch => ({

  handleChangeLocale: locale => {
    dispatch(changeLocale(locale));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(NeoLocaleChange);
