import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import NeoRouter from './routes/Router';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';
import PopInMessage from './components/PopInMessage';
import { createTheme } from '@material-ui/core/styles';
import { enUS } from '@material-ui/core/locale';
import availableLocales from './elements/NeoLocale/availableLocales';
import { changeLocale } from './redux/reducers/layoutConfigReducer';
import i18nConf from './services/i18n';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';
import lightTheme from './styles/commonTheme';


function App({ theme, locale, handleChangeLocale }) {
  const { i18n } = useTranslation();
  const [usedTheme, setTheme] = useState(createTheme(lightTheme, enUS));

  if (process.env.REACT_APP_ENV === "inte" || process.env.REACT_APP_ENV === "development") {
    useKey("}", () => {
      let newlang = ""
      switch (i18n.language) {
        case "fr":
          newlang = "en"
          break;
        case "en":
          newlang = "cimode"
          break;
        case "cimode":
          newlang = "fr"
          break;
      }

      handleChangeLocale(newlang)
      i18nConf.changeLanguage(newlang)
    }, {
      // shiftKey: true,
      // altKey: true,
    });
  }

  useEffect(() => {
    let themeToUse = lightTheme; // by default
    let localeToUse = enUS;

    const matchingLocale = availableLocales.find((item) => item.localeKey === locale);
    if (matchingLocale) {
      localeToUse = matchingLocale.materialTheme;
    }

    setTheme(createTheme(themeToUse, localeToUse));
  }, [theme, locale]); // use locale here so we refresh the theme for locale too

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>RCBE</title>
      </Helmet>
      <ThemeProvider theme={usedTheme}>
        <NeoRouter />
        <PopInMessage />
      </ThemeProvider>
    </div>
  );
}

App.propTypes = {
  theme: T.string.isRequired,
  locale: T.string,
};

App.defaultProps = {
  locale: null,
};

const mapStateToProps = (state) => ({
  theme: state.layoutConfig.theme,
  locale: state.layoutConfig.locale,
});

const mapDispatchToProps = dispatch => ({

  handleChangeLocale: locale => {
    dispatch(changeLocale(locale));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
