import AuthorizedRoutes from '../AuthorizedRoutes';
import { routes } from './dashboardRoutesList';
import { Redirect } from 'react-router-dom';
import React from 'react';
import NoMatch from '../../elements/NeoAlerts/ErrorPage';
import FakeLogin from '../../pages/FakeLogin';

const redirectToSp = () => (<Redirect to="/sp/home" />);

export const routesList = [
  {
    exact: true,
    path: '/',
    // eslint-disable-next-line no-use-before-define
    component: redirectToSp
  },

  {
    path: '/login',
    component: FakeLogin,
  },

  // authentified stuff here
  {
    path: '/sp',
    // eslint-disable-next-line no-use-before-define
    component: AuthorizedRoutes,
    routes: routes,
  },
  {
    path: '/health',
    component: () => (<div><p>OK</p></div>),
  },
  {
    path: '*',
    component: NoMatch
  }
];
