// RcbeList.js
import React, { useEffect, useState } from 'react';
import NeoTable from '../../elements/NeoLayouts/NeoTable';
import fetchData from '../../request/fetchData';
import { Info } from '@material-ui/icons';
import { endpoints } from '../../request/endpoints';
import { formatDate } from '../../helpers/format';

 const columnsList = [
  { label: 'Raison Sociale', id: 'entityName' },
  { label: 'NIU', id: 'entityNIU' },
  { label: 'Type', id: 'entityLegalType' },
   {
     sortable: false,
     width: 200,
     id: '_id',
     label: 'Actions',
     buttons: [
       {
         buttonColor: 'secondary',
         buttonLabel: 'detail',
         buttonIcon: <Info />,
         linkTo: 'detail', // here linkTo will trigger a button with router and id row passed as query params
         linkKey: 'entityId'
         // linkParams: '_id'
       },
     ],

   },
  ];

function EntitiesList() {
  const [rcbes, setRcbes] = useState([]);

  useEffect(() => {
    // const queryParams = {status: 'submitted'};
    const queryParams = {};
    fetchData(endpoints.entitiesList, queryParams).then((data) => setRcbes(data));
  }, []);

  if (!rcbes || !rcbes.length) {
    return null;
  }

  return (
      <NeoTable rows={rcbes} columns={columnsList} />
  );
}

export default EntitiesList;
