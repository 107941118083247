// TaxpayerBusinesses.js
import React, { useEffect, useState } from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import fetchData from '../../request/fetchData';
import endpoints from '../../request/endpoints';
import { Graph } from 'react-d3-graph';
import DraggableDialog from '../../elements/DraggableDialog';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';

function TabLink(props) {
  return <Link to={props.to} {...props} />;
}
function TaxpayerBusinesses() {
  // const { entityBeneficiaryNIU } = useParams();
  const [data, setData] = useState(null);
  const [openNodeFocus, setOpenNodeFocus] = useState(false);
  const [nodeFocusTitle, setNodeFocusTitle] = useState('');
  const [nodeFocusContent, setNodeFocusContent] = useState('');
  const [nodeId, setNodeId] = useState(null);

  const location = useLocation();
  // let { id } = useParams();

  // Extraire les query params
  const queryParams = new URLSearchParams(location.search);
  const entityBeneficiaryNIU = queryParams.get('entityBeneficiaryNIU');

  // Handler pour le clic sur le nœud
  const onClickNode = function(nodeIdd, node) {
    console.log('clcclc', node, nodeIdd);
    if (node) {
      setNodeId(nodeIdd)
      setNodeFocusTitle(node.label);
      setNodeFocusContent(node.tooltipContent);
      setOpenNodeFocus(true);
    }
  };


  // Configuration de react-d3-graph
  const graphConfig = {
    "height": 600,
    "width": 800,
    d3: {
      "gravity": -1000,
      "linkLength": 170,
      // "linkStrength": 1,
    },
    node: {
      'highlightColor': 'red',
      'highlightFontSize': 12,
      'highlightFontWeight': 'bold',
      'highlightStrokeColor': 'blue',
      'highlightStrokeWidth': 1.5,
      color: 'lightgreen',
      size: 120,
      fontSize: 13,
      labelProperty: 'label',
      renderLabel: true,
    },
    link: {
      highlightColor: 'lightblue',
    },
  };


  // node clickable pour rediriger,
  // graph à plusieurs niveaux
  // taille de noeud selon nombre d'actions

  useEffect(() => {
    fetchData(endpoints.ecoSynthesis,
      {
        physicalTaxPayerNIU: entityBeneficiaryNIU,
        showGraph: 'true',
      })
      .then(dddt => setData(dddt));
  }, [entityBeneficiaryNIU]);

  if (!data || !data.links || !data.links.length) {
    return null;
  }

  let linkCompo= null;
  if (nodeFocusContent) {
    if (nodeFocusContent.entityId) {
      linkCompo = <Button
        variant="outlined"
        color="primary"
        href={`/sp/declaration/detail?entityId=${nodeFocusContent.entityId}`}
      >
        Consulter le RCBE de ce NIU
      </Button>;
    }
    else if (nodeFocusContent.entityBeneficiaryNIU) {
      linkCompo = <Button
        variant="outlined"
        color="secondary"
        href={`/sp/taxPayers/detailGraph?entityBeneficiaryNIU=${nodeFocusContent.entityBeneficiaryNIU}`}
      >
        Consulter le graph pour ce NIU
      </Button>;
    }
  }

  return (
    <Container>
      <DraggableDialog
        openState={openNodeFocus}
        onClose={() => {setOpenNodeFocus(false)}}
        title={nodeFocusTitle}
        content={nodeFocusContent}
        link={linkCompo}
      />

      <Typography variant="h4" gutterBottom>Graphique relations economico-juridiques</Typography>

      <Paper >
        <Tabs
          value={1}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Tableaux relations" component={TabLink} to={`/sp/taxPayers/detail?entityBeneficiaryNIU=${entityBeneficiaryNIU}`} />
          <Tab label="Graph relations" component={TabLink} to={`/sp/taxPayers/detailGraph?entityBeneficiaryNIU=${entityBeneficiaryNIU}`} />
        </Tabs>
      </Paper>

      <Graph
        id="graph-id" // id est obligatoire
        data={data}
        config={graphConfig}
        onClickNode={onClickNode}
        style={{ width: '100%', height: '80vh' }}
      />
    </Container>
  );
}

export default TaxpayerBusinesses;
