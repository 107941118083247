import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import TranslatedContent from './NeoLocale/Components/NeoTranslatedContent';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({openState, onClose, title, content, link}) {
  const [open, setOpen] = React.useState(openState||false);

  useEffect(() => {
    if (open !== openState) {
      setOpen(openState);
    }
  }, [openState]);

  const handleClose = (e) => {
    e.preventDefault();
    // setOpen(false);
    onClose();
  };

  if (!content) {
    return null
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
            {Object.entries(content).map(([key, val]) => {
              return (<li key={key}><TranslatedContent localKey={key}/> : {val}</li>);
            })}
            </ul>
          </DialogContentText>
        </DialogContent>
        {link
          ? (<DialogActions>
            {link}
          </DialogActions>)
          : null}
      </Dialog>
  );
}
