import {
  frFR,
  enUS,
} from '@material-ui/core/locale';
import {
  fr as frDate,
  enUS as enDate,
} from 'date-fns/locale';

export default [
  {
    label: 'Français', // label shown no matter the locale
    localeKey: 'fr', // the locale we really use
    materialTheme: frFR, // used by material ui in theme so we can change default labels
    dateLocale: frDate, // date fn locale object used by date picker and other dates components
    dateLocaleFormat: 'dd/MM/yyyy',
  },
  {
    label: 'English',
    localeKey: 'en',
    materialTheme: enUS,
    dateLocale: enDate,
    dateLocaleFormat: 'MM/dd/yyyy',
  },
];
