import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  button: {
    margin: theme.spacing(1),
  },


  buttonColorerror: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
    },
  },

}));


export function NeoTableButton({onDelete, row, hideButton, color, variant, buttonIcon, linkTo, linkParams, valueBase, label, style, onClick, onSuccess, hideOnSuccess }) {
  const classes = useStyles();
  const [hideBtn, setHideBtn] = useState(hideButton);

  let urlForLink = linkTo;
  let component = RouterLink;

  // this will set valueBase as parameter
  // if (valueBase) {
  //   urlForLink = `${urlForLink}${valueBase}`;
  // }

  if (linkParams) {
    urlForLink = `${urlForLink}${linkParams}`;
  }

  let onClickFunction = null;
  let onSuccessFunction = null;

  if (onSuccess) {
    const cb = hideOnSuccess ? () => setHideBtn(true) : null;
    onSuccessFunction = () => onSuccess(cb);
  }

  if (onClick) {
    urlForLink = null;
    component = 'button';
    onClickFunction = () => onClick(valueBase, onSuccessFunction ? () => onSuccessFunction() : null, row);
  }

  if (onDelete) {
    urlForLink = null;
    component = 'button';
    onClickFunction=() => onDelete(row)
  }

  const className = ['primary', 'seconday', 'inherit'].includes(color) ? null : classes[`buttonColor${color}`];

  let componentBtn = (
    <Button
      size="small"
      style={style}
      className={className}
      variant={variant}
      color={color !== 'tertiary' ? color : 'inherit'}
      component={component}
      to={urlForLink}
      onClick={onClickFunction}
    >
      {buttonIcon}
      {buttonIcon && label ? '\u00A0' : null}
      {label}
    </Button>
  );


  if (hideBtn) {
    return null;
  }

  return componentBtn;
}

NeoTableButton.propTypes = {
  buttonIcon: PropTypes.node,
  label: PropTypes.string,
  popoverText: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  linkTo: PropTypes.string,
  linkParams: PropTypes.string,
  toolTip: PropTypes.string,
  valueBase: PropTypes.string,
  style: PropTypes.object,
  hideButton: PropTypes.bool,
  color: PropTypes.string,
  hideOnSuccess: PropTypes.bool,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
};

NeoTableButton.defaultProps = {
  variant: 'contained',
  hideButton: false,
  color: 'inherit',
  hideOnSuccess: false,
  buttonIcon: null,
  onClick: null,
  onSuccess: null,
  label: null,
  popoverText: null,
  linkTo: null,
  linkParams: null,
  toolTip: null,
  valueBase: null,
  style: null,
};
