// DeclarationList.js
import React, { useEffect, useState } from 'react';
import NeoTable from '../../elements/NeoLayouts/NeoTable';
import fetchData from '../../request/fetchData';
import { Info } from '@material-ui/icons';
import { endpoints } from '../../request/endpoints';
import { formatDate } from '../../helpers/format';

 const columnsList = [
  { label: 'Année fiscale', id: 'fiscalYear' },
  { label: 'Date de Déclaration', id: 'createdAt', format: (value) => formatDate(value) },
  { label: 'Statut de Déclaration', id: 'status' },

  { label: 'Raison Sociale', id: 'entity.entityName' },
  { label: 'NIU', id: 'entity.entityNIU' },
  { label: 'Type', id: 'entity.entityLegalType' },
   {
     sortable: false,
     width: 200,
     id: '_id',
     label: 'Actions',
     buttons: [
       {
         buttonColor: 'secondary',
         buttonLabel: 'detail',
         buttonIcon: <Info />,
         linkTo: 'detail', // here linkTo will trigger a button with router and id row passed as query params
         linkKey: '_id'
         // linkParams: '_id'
       },
     ],

   },
  ];

function DeclarationList() {
  const [declarations, setDeclarations] = useState([]);

  useEffect(() => {
    // const queryParams = {status: 'submitted'};
    const queryParams = {};
    fetchData(endpoints.declarationsList, queryParams).then((data) => setDeclarations(data));
  }, []);

  if (!declarations || !declarations.length) {
    return null;
  }

  return (
      <NeoTable rows={declarations} columns={columnsList} />
  );
}

export default DeclarationList;
