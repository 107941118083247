import i18n from '../services/i18n';

function formatDate(date, locale = i18n.language || undefined, dateOptions = undefined) {
  const event = new Date(date);
  // dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  return event.toLocaleDateString(locale || 'en', dateOptions);
}

export {  formatDate,
};
