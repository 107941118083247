import i18n from '../../services/i18n';

function neoDynamicTranslate(key, options = null) {
  if (key) {
    return i18n.t(key, options);
  }

  return "";
}

// for options list search :TOptionsBase {
//   /**
//    * Default value to return if a translation was not found
//    */
//   defaultValue?: any;
//   /**
//    * Count value used for plurals
//    */
//   count?: number;
//   /**
//    * Used for contexts (eg. male\female)
//    */
//   context?: any;
//   /**
//    * Object with vars for interpolation - or put them directly in options
//    */
//   replace?: any;
//   /**
//    * Override language to use
//    */
//   lng?: string;
//   /**
//    * Override languages to use
//    */
//   lngs?: string[];
//   /**
//    * Override language to lookup key if not found see fallbacks for details
//    */
//   fallbackLng?: FallbackLng;
//   /**
//    * Override namespaces (string or array)
//    */
//   ns?: string | string[];
//   /**
//    * Override char to separate keys
//    */
//   keySeparator?: false | string;
//   /**
//    * Override char to split namespace from key
//    */
//   nsSeparator?: false | string;
//   /**
//    * Accessing an object not a translation string (can be set globally too)
//    */
//   returnObjects?: boolean;
//   /**
//    * Char, eg. '\n' that arrays will be joined by (can be set globally too)
//    */
//   joinArrays?: string;
//   /**
//    * String or array of postProcessors to apply see interval plurals as a sample
//    */
//   postProcess?: string | string[];
//   /**
//    * Override interpolation options
//    */
//   interpolation?: InterpolationOptions;


// bind some events and fill values again (doing the magic you expect to happen magically)
// i18n.on('languageChanged init', () => {
// neoDynamicTranslate();
// fills myExport with new value for foo based on current i18n lng
// });

// export the const
export default neoDynamicTranslate;
