// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

function TranslatedContent({ localKey, count, explicit, hideCounter }) {
  const { t } = useTranslation();
  const countObjet = count > 1 ? { count } : null;
  let content = t(localKey, countObjet);

  if (!localKey || !content || !content.length) {
    if (countObjet) {
      // maybe we can find a singular entry for this key
      content = t(localKey.replace('_plural', ''), countObjet);
    }

    if (!content && (!process.env.NODE_ENV || process.env.REACT_APP_ENV === 'development')) {
      content = `${localKey} NOT FOUND IN TRANSLATIONS`;
    }
    else {
      content = '';
    }
  }

  if (hideCounter && count > 1) {
    content = content.substring((String(count)).length);
  }

  if (count === 1 && !hideCounter) {
    content = `1 ${content}`;
  } else if (count === 0 && explicit && !hideCounter) {
    content = `0 ${content}`;
  }

  return (content);
}

TranslatedContent.propTypes = {
  localKey: T.string,
  count: T.number,
  explicit: T.bool,
  hideCounter: T.bool,
};

TranslatedContent.defaultProps = {
  localeKey: null,
  count: null,
  explicit: false,
  hideCounter: false,
};

export default TranslatedContent;
