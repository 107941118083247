import React from 'react';
import T from 'prop-types';
import { deleteUser } from '../redux/reducers/sessionReducer';
import { connect } from 'react-redux';
import { logout } from '../actions/sessions';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TranslatedContent from '../elements/NeoLocale/Components/NeoTranslatedContent';

function Logout({ deleteUserFn}) {

  async function handleSubmit(event) {
    event.preventDefault();
    await logout()(deleteUserFn);
  }

  return (<MenuItem onClick={handleSubmit} style={{color: 'crimson'}}>
    <ListItemIcon style={{color: 'crimson'}}>
      <ExitToAppIcon />
    </ListItemIcon>
    <TranslatedContent localKey={'logoutSection'}/>
  </MenuItem>);
}

Logout.propTypes = {
  deleteUserFn: T.func.isRequired,
};

const mapDispatchToProps = {
  deleteUserFn: deleteUser,
};

export default connect(null, mapDispatchToProps)(Logout);
