

const authorizedComponentsList = [
  // Declarations
  {
    name: 'ViewDeclaration',
    resources: ['getDeclaration'],
    entityRoles: ['readPrice']
  },
  {
    name: 'EditDeclaration',
    resources: ['editDeclaration'],
    entityRoles: ['editPrice']
  },
  {
    name: 'ValidateDeclaration',
    resources: ['validateDeclaration'],
    entityRoles: ['validatePrice']
  },
];

export default authorizedComponentsList;
