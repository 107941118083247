import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import { NavLink, useLocation } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TranslatedContent from '../NeoLocale/Components/NeoTranslatedContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

  drawer: {
    backgroundColor: theme.palette.primary.dark,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: theme.toolbarHeight,
  },
  drawerOpen: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    top: theme.toolbarHeight,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenTrial: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    top: 66,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    top: theme.toolbarHeight,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    '&:hover': {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
        delay: '0.75s',
      }),
    }
  },
  drawerDivider: { color: '#c8c8c84f' },
  routeSelectorIcon: {
    color: theme.palette.primary.contrastText,
    minWidth: 40,
  },
  routeSelectorChildren : {
    fontSize: '0.92rem !important',
    margin: 0,
  },
  listitem: {
    display: "flex",
    width: "100%",
    border: "none",
    color: "white",
    backgroundColor: "inherit",
    paddingLeft: 16,
    paddingRight: 16,
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "flex-start",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

const ListItem = withStyles({
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.20) !important'
  },
  container: {
    padding: 0
  }
})(MuiListItem);

const MenuListItem = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    display: "inline-block",
    padding: 0
  },
})(MuiListItem);

const SubListItem = withStyles({
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
  },
})(MuiListItem);


function NeoDrawer({ drawerIsOpen, allowedRoutes, trialBanner = false }) {
  const location = useLocation();
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null)

  const [open, setOpen] = useState("");

  const handleMouseEnter = event => {
    setDelayHandler(setTimeout(() => {
      setIsShown(true)
    }, 750))
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    setIsShown(false)
  }

  const openMenu = (path) => {
    setOpen(path === open ? "" : path)
  };

  const testSubMenuActive = (children, location) => {
    if (location.pathname == children.path) {
      return true
    }

    if (location.pathname + location.search == children.path) {
      return true
    }

    if (children.additionnalPathsMenu) {
      for (let adR of children.additionnalPathsMenu) {
        if (adR.exec(location.pathname)) {
          return true
        }

        if (adR.exec(location.pathname + location.search)) {
          return true
        }
      }
    }

    return false
  }

  // Lors du reload on réouvre le bon menu
  useEffect(() => {
    for (let ar of allowedRoutes) {
      if (location.pathname.indexOf(ar.path) !== -1 && ar.path !== "/sp") {
        if (ar.path !== open) {
          setOpen(ar.path)
        }
      }
    }
  }, [location.pathname])


  return (
    <Drawer
      variant="permanent"
      open={drawerIsOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerIsOpen,
        [classes.drawerClose]: !drawerIsOpen,
        [classes.drawerOpenTrial]: !!trialBanner,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerIsOpen,
          [classes.drawerClose]: !drawerIsOpen,
          [classes.drawerOpenTrial]: !!trialBanner,
        }),
      }}
    >
      <Divider className={classes.drawerDivider} />
      <List component="nav" disablePadding>
        {allowedRoutes.map((route) => {
          let children = route.children ? route.children.filter(element => element.labelKey && element.labelKey !== "") : []

          if (route.labelKey && !route.parent) {
            // this section has subsection buttons
            if (children.length > 0) {
              if (children.length > 1) {
                if (route.labelKey && !route.hideFromMenu) {
                  return (
                    <React.Fragment key={route.path}>
                      <button
                        className={classes.listitem}
                        key={route.path}
                        component={NavLink}
                        onClick={() => openMenu(route.path)}
                      // exact
                      >
                        <ListItemIcon className={classes.routeSelectorIcon}> {route.icon} </ListItemIcon>
                        <ListItemText primary={<TranslatedContent localKey={route.labelKey} />} />
                        {open === route.path ? <ExpandLess /> : <ExpandMore />}
                      </button>
                      <Collapse in={open === route.path && (drawerIsOpen || isShown)} timeout="auto" unmountOnExit>
                        <MenuListItem component="div" disablePadding>
                          {route.children.map((children) => {
                            if (children.labelKey && !children.hideFromMenu) {
                              return <SubListItem
                                button
                                exact
                                key={children.path}
                                to={children.path}
                                component={NavLink}
                                selected={testSubMenuActive(children, location)}
                              >
                                <ListItemIcon className={classes.routeSelectorIcon}> {children.icon} </ListItemIcon>
                                <ListItemText className={classes.routeSelectorChildren} disableTypography primary={<TranslatedContent localKey={children.labelKey} />} />
                              </SubListItem>
                            } else {
                              return null
                            }
                          })}
                        </MenuListItem>
                      </Collapse>
                    </React.Fragment>
                  );
                } else {
                  return null
                }
              } else {
                return <ListItem
                  button
                  key={children[0].path}
                  component={NavLink}
                  to={children[0].path}
                  onClick={() => setOpen("")}
                  selected={testSubMenuActive(children[0], location)}
                // exact
                >
                  <ListItemIcon className={classes.routeSelectorIcon}> {route.icon} </ListItemIcon>
                  <ListItemText primary={<TranslatedContent localKey={children[0].labelKey} />} />
                </ListItem>
              }
            }
            // only one section button and no sublevels means no collapse
            else {
              return <ListItem
                button
                key={route.path}
                component={NavLink}
                to={route.path}
                onClick={() => setOpen("")}
                selected={testSubMenuActive(route, location)}
              // exact
              >
                <ListItemIcon className={classes.routeSelectorIcon}> {route.icon} </ListItemIcon>
                <ListItemText primary={<TranslatedContent localKey={route.labelKey} />} />
              </ListItem>
            }
          }
          return null;
        })}
      </List>
    </Drawer >
  );
}

export default NeoDrawer;
