import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-chained-backend';
// eslint-disable-next-line no-unused-vars
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line no-unused-vars
import endpoints from '../request/endpoints';

// fichier locaux fallback
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
    },
    fallbackLng: 'en',
    debug: false,
    load: ['currentOnly'],
    preload: ['fr', 'en'], // if we want preload IT, ZH etc here too
    react: { useSuspense: false }, // if problems with loading..

    // defaultNS: 'translations',

    backend: {
      backends: [
        // LocalStorageBackend, // primary // TODO put this back !
        HttpBackend // fallback
      ],
      backendOptions: [
        {
          // expiration
          expirationTime: 1 * 24 * 60 * 60 * 1000, // n DAYS => 1

          // Version applied to all languages, can be overriden using the option `versions`
          // defaultVersion: '',

          // language versions
          // versions: {},

          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          // store: window.localStorage

          loadPath: `${endpoints.localeTranslations}/{{lng}}.json`,

          // allowMultiLoading: false,

          requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
            mode: 'cors',
            credentials: 'include', // with CORS this is essential to use the set-cookie header response

          },
          // allow cross domain requests
          // crossDomain: true,
          // allow credentials on cross domain requests
          // withCredentials: true,
        }
      ]
    },
    detection: {
      // order and from where user language should be detected
      // a custom detector with addDetector can request locale to server, than we add this detector to the 'order' array
      // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

      // keys or params to lookup language from
      // lookupQuerystring: 'lng',
      // lookupLocalStorage: 'i18nextLng',
      // lookupFromPathIndex: 0,
      // lookupFromSubdomainIndex: 0,

      // cache user language on
      // caches: ['localStorage', 'cookie'],
      // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      // htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'none' }
    }
  });

export default i18next;
