import React from 'react';
import {Redirect } from 'react-router-dom';

function NoMatch() {
  // let location = useLocation();

  return <Redirect to="/"/>;

  // eslint-disable-next-line no-unreachable
  /*
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
   */
}

export default NoMatch;
