import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RouteWithSubRoutes from './SubRoutes';
import { routesList } from './list/firstLevelRoutesList';

function NeoRouter() {

  return (
      <Router>
        <Switch>
          {routesList.map((route) => (
            <RouteWithSubRoutes
              key={route.path}
              {...route}
            />
          ))}
        </Switch>
      </Router>
  );
}

export default NeoRouter;
