// TaxpayerBusinesses.js
import React, { useEffect, useState } from 'react';
import { Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import NeoTable from '../../elements/NeoLayouts/NeoTable';
import { Link, useLocation } from 'react-router-dom';
import fetchData from '../../request/fetchData';
import endpoints from '../../request/endpoints';
function TabLink(props) {
  return <Link to={props.to} {...props} />;
}
function TaxpayerBusinesses() {
  // const { entityBeneficiaryNIU } = useParams();
  const [data, setData] = useState(null);
  const location = useLocation();
  // let { id } = useParams();

  // Extraire les query params
  const queryParams = new URLSearchParams(location.search);
  const entityBeneficiaryNIU = queryParams.get('entityBeneficiaryNIU');

  const columns = [
    { label: 'Raison Sociale', id: 'entityName' },
    { label: 'NIU', id: 'entityNIU' },
    { label: 'RCCM', id: 'entityRccmNum' },
    // { label: 'Raison pour laquelle il est Bénéficiaire Effectif', id: 'beneficiaryReason' },
    { label: 'Nb Parts Directes', id: 'entityBeneficiaryDirectShareNumber' },
    { label: '%  Parts Directes', id: 'entityBeneficiaryDirectSharePercent' },
    { label: 'Nb Parts Indirectes', id: 'entityBeneficiaryIndirectShareNumber' },
    { label: '%  Parts Indirectes', id: 'entityBeneficiaryIndirectSharePercent' },
    // { label: 'Date de Déclaration', id: 'createdAt', format: (value) => formatDate(value) },
  ];

  useEffect(() => {
    fetchData(endpoints.ecoSynthesis,
      {
        physicalTaxPayerNIU: entityBeneficiaryNIU,
        // showGraph: 'true',
      })
      .then(dddt => setData(dddt));
  }, [entityBeneficiaryNIU]);

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Ecosynthèse du contribuable : {data.beneficiaryFirstName} {data.beneficiaryName} ({data.beneficiaryNIU})</Typography>
      <Paper >
        <Tabs
          value={0}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Tableaux relations" component={TabLink} to={`/sp/taxPayers/detail?entityBeneficiaryNIU=${entityBeneficiaryNIU}`} />
          <Tab label="Graph relations" component={TabLink} to={`/sp/taxPayers/detailGraph?entityBeneficiaryNIU=${entityBeneficiaryNIU}`} />
        </Tabs>
      </Paper>

      <NeoTable rows={data.beneficiaryData} columns={columns} />
    </Container>
  );
}

export default TaxpayerBusinesses;
