/* eslint-disable no-undefined */
import availableLocales from '../../elements/NeoLocale/availableLocales';

const defaultViewKey = 'NeoDefaultView';
const localeKey = 'NeoLocale';
const fastCopyKey = 'NeoFastCopy';

// TODO test that we fetch the theme from the backend and store it too
const initialState = {
  theme: 'light',
  locale: localStorage.getItem(localeKey) || null, // null will allow i18n to autodetect something
  message: null,
};

export const changeLocale = (locale) => ({
  type: 'CHANGE_LOCALE',
  value: locale,
});

export const showMessage = ({ content, messageType, timeout }) => {
  // console.log('action show messs redux', content);
  return {
    type: 'SHOW_MESSAGE',
    content,
    messageType,
    timeout,
  };
};

export const removeLayoutData = () => {
  localStorage.removeItem(defaultViewKey);
  localStorage.removeItem(fastCopyKey);
};

function changeConfig(state = initialState, action) {
  let nextState = null;
  switch (action.type) {
    case 'CHANGE_LOCALE':
      if (availableLocales.some((elem) => elem.localeKey === action.value) || action.value === 'cimode') {
        nextState = {
          ...state,
          locale: action.value,
        };
        localStorage.setItem(localeKey, action.value);
        return nextState || state;
      }
      return state;

    case 'SHOW_MESSAGE':
      const messageObj = action.content ? { content: action.content, messageType: action.messageType || 'error', timeout: action.timeout || null } : null;
      nextState = {
        ...state,
        message: messageObj,
      };
      return nextState || state;

    default:
      return state;
  }
}
export const setLayoutData = (layoutConfiguration = {}, helpsSeen = []) => {
  const { locale, theme } = layoutConfiguration;

  // const currentLocale = localStorage.getItem(localeKey) || null;
  // const currentTheme = localStorage.getItem(themeKey) || null;

  if (locale) {
    // store.dispatch(changeLocale(locale));
    changeConfig(undefined, { type: 'CHANGE_LOCALE', value: locale });
  }
};

export default changeConfig;
