import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Settings from '../Settings/Settings';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../routes/list/dashboardRoutesList';
import Logout from '../../Logout';
import TranslatedContent from '../../../elements/NeoLocale/Components/NeoTranslatedContent';
import { connect } from 'react-redux';
import Grow from '@material-ui/core/Grow';
import SettingsIcon from '@material-ui/icons/Settings';
import NeoDrawer from '../../../elements/NeoLayouts/NeoDrawer';
import neoDynamicTranslate from '../../../elements/NeoLocale/neoDynamicTranslate';

const drawerWidth = 240;

const CurrentSectionLabel = () => {
  const location = useLocation();

  const foundLabel = routes.find((elem) => elem.pathnameLabel === location.pathname);

  if (foundLabel && foundLabel.labelKey) {
    document.title = `RCBE - ${neoDynamicTranslate(foundLabel.labelKey)}`;
    return <TranslatedContent localKey={foundLabel.labelKey} />;
    // return foundLabel.label;
  }
  document.title = 'RCBE';
  return '';
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: theme.palette.primary.dark,
    // backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.primary,
  },
  currentSection: {
    marginLeft: 15,
  },

  '@keyframes showEffect': {
    '0%': { opacity: 0.5 },
    '100%': {
      opacity: 1,
    },
  },

  menuButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    margin: 0,
    width: theme.spacing(7) + 1,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButtonOpen: {
    backgroundColor: theme.palette.primary.main,
    animation: '$showEffect 2s',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,

    backgroundImage: 'url("/img/logo/blanc_rcbe.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 170,
    backgroundPosition: 'center',
  },
  menuButtonLabelOpen: {
    justifyContent: 'start',
    paddingLeft: 5
  },

  hide: {
    display: 'none',
  },

  toolbar: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    paddingLeft: 0,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: 'flex',
  },
  profileButton: {
    color: theme.palette.quaternary.dark,
    // color: '#ffb21e',
  },
}));

/*
function ElevationScroll({children}) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};
*/

function DashMenu({ userEntityRoles, allowedRoutesWithChildren, showGoBack, userType, userSubType }) {
  const classes = useStyles();
  const drawerIsOpen = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsShown, showSettings] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      TransitionComponent={Grow}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      <MenuItem onClick={() => { setAnchorEl(null); showSettings(true); }}>
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <TranslatedContent localKey={'accountSection'} />
      </MenuItem>


      {process.env.REACT_APP_ENV !== 'production'
        ?
        <MenuItem>
          <p>BETA INTE |&nbsp;</p>
          <p style={{ color: '#ffb21e', textTransform: 'uppercase', fontWeight: 'bold' }}>{userType} &nbsp; {userSubType || ''}</p>
        </MenuItem>
        : null}

      {/* <MenuItem><TranslatedContent localKey={'helpMePdf'}/></MenuItem> */}
      <Divider />

      <Logout />
    </Menu>
  );

  return (
    <>
      {/* <ElevationScroll>*/}

      <AppBar
        position="fixed"
        className={classes.appBar}
        color={'inherit'}
        elevation={0}
      >

        <Toolbar
          className={classes.toolbar}
          variant='dense'
        >

          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={toggleDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.menuButtonOpen]: drawerIsOpen,
            })}
            classes={drawerIsOpen ? { label: classes.menuButtonLabelOpen } : {}}
            style={{ height: 50 }}
          >
            <br />
            {/* <MenuIcon /> */}
            {/* {drawerIsOpen && <span>&nbsp; Rcbe</span>} */}
            {/* {drawerIsOpen && <img className={classes.iconLogoButton} height={40} src={'/img/logo/blanc_rcbe.png'}></img>}*/}
          </IconButton>


          {showGoBack
            ? <NeoGoBack />
            : <Typography variant="h6" noWrap className={classes.currentSection}>
              <CurrentSectionLabel />
            </Typography>}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            {/* <NotificationsContainer /> */}
            {/*
              <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon/>
                </Badge>
              </IconButton>
              */ }
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              className={classes.profileButton}
            >
              <AccountCircle />
            </IconButton>

          </div>

        </Toolbar>
      </AppBar>
      {/* </ElevationScroll>*/}
      {renderMenu}
      {settingsShown && <Settings toggleDisplay={showSettings} />}

      <NeoDrawer allowedRoutes={allowedRoutesWithChildren} drawerIsOpen={drawerIsOpen} />

    </>
  );
}

DashMenu.propTypes = {
  userEntityRoles: PropTypes.array,
  userClientRoles: PropTypes.array,
  allowedRoutesWithChildren: PropTypes.array,
  showGoBack: PropTypes.bool,
  userType: PropTypes.string,
  showHelpFn: PropTypes.func,
};

DashMenu.defaultProps = {
  userEntityRoles: [],
  userClientRoles: [],
  allowedRoutesWithChildren: [],
  showGoBack: false,
  userType: null,
};

const mapStateToProps = ({
  session: {
    userEntityRoles,
    userClientRoles,
    allowedRoutesWithChildren,
    userType,
    userSubType,
  },
  layoutConfig: {
    showGoBack
  }
}) => {
  return {
    userType,
    userSubType,
    userEntityRoles,
    userClientRoles,
    allowedRoutesWithChildren,
    showGoBack,
  };
};

export default connect(mapStateToProps)(DashMenu);
