import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import T from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LayoutSettings from './LayoutSettings';
import ProfileSettings from './ProfileSettings';
import TranslatedContent from '../../../elements/NeoLocale/Components/NeoTranslatedContent';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    height: `calc(100% - ${theme.toolbarHeight}px)`,
    backgroundColor: theme.palette.background.default,
  },
  settingsGrid: {
    // height: '100%',
    width: '100%',
    margin: 0,
    padding: theme.spacing(2),
  },
  settingsContainer: {
    // width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  settingsSection: {
    // padding: 10
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.dark,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Settings({ toggleDisplay }) {
  const classes = useStyles();

  const handleClose = () => {
    toggleDisplay(false);
  };

  return (
    <div>
      <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <TranslatedContent localKey={'accountSection'} />
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>*/}
            {/*  save*/}
            {/* </Button>*/}
          </Toolbar>
        </AppBar>
        <div className={classes.gridRoot}>
          <Grid
            container
            // direction="row"
            spacing={3}
            // alignItems="center"
            // justifyContent="center"
            className={classes.settingsGrid}
          >
            <Grid item md={6} xs={12}>
              <Paper className={classes.settingsSection}>
                <ProfileSettings />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.settingsSection}>
                <LayoutSettings />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.settingsSection}>
              </Paper>
            </Grid>
          </Grid>

        </div>
      </Dialog>
    </div>
  );
}

Settings.propTypes = {
  toggleDisplay: T.func.isRequired,
};
