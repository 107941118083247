import { post, standardServerError } from '../request';
import endpoints from '../request/endpoints';

const login = (email, password, captcha, hpId) => (
  // eslint-disable-next-line no-unused-vars
  async (setUser) => {
    try {

      const res = await post(endpoints.login, {email, password, captcha, hpId});

      // if (res.status === 200) {
      //   setUser({
      //     payload: res.body,
      //   });
      //   window.location.replace('/sp');
      // }
      return res;
    } catch (e) {
      return standardServerError;
    }
  }
);

const logout = () => (
  async (deleteUser) => {
    try {
      await post(endpoints.logout);
      deleteUser();

      // window.location.replace('/login'); // redirect is of no use
    } catch (e) {
      deleteUser();
      return standardServerError;
    }
  }
);

export {login, logout};
