import neoDynamicTranslate from '../elements/NeoLocale/neoDynamicTranslate';

const myInit = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  // credentials: 'same-origin'
  credentials: 'include', // with CORS this is essential to use the set-cookie header response
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
// todo : use proxy for some requests

const standardServerError = { errorInfos: { error: neoDynamicTranslate('serverErrorPopin') } };

const requestResponse = (body = null, status = 200, message = null, errorInfos = null) => {
  const failed = (status >= 400);
  const disconnect = (status === 401);
  const locked = (status === 423);

  // const {error, errorType, data, dataKey} = errorInfos;

  return {
    status,
    message,
    body,
    failed,
    errorInfos,
    disconnect,
    locked,
  };
};

async function getBinaryAsBlob(url, controller, params = {}) {
  try {
    const getUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) => getUrl.searchParams.append(key, params[key]));
    }

    const options = myInit;
    if (controller) {
      options.signal = controller.signal;
    }
    const res = await fetch(getUrl.toString(), options);

    if (res.ok) {
      const blob = await res.blob();
      return requestResponse(blob);
    }
    return requestResponse(null, res.status, res.statusText);
  } catch (e) {
    requestResponse(null, e.status, e.statusText);
  }
}

async function get(url, controller = null, params = {}) {
  try {
    const getUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) => getUrl.searchParams.append(key, params[key]));
    }

    const options = myInit;
    if (controller) {
      options.signal = controller.signal;
    }

    const res = await fetch(getUrl.toString(), options);

    const json = await res.json();
    if (res.ok) {
      return requestResponse(json);
    }
    return requestResponse(null, res.status, res.statusText, json);
  } catch (e) {
    requestResponse(null, e.status, e.statusText);
  }
}

async function postPutDel(url, method = 'POST', body = null, queryParams = null, controller = null) {
  try {
    const getUrl = new URL(url);
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => getUrl.searchParams.append(key, queryParams[key]));
    }

    const options = {
      ...myInit,
      method: method,
      body: body && JSON.stringify(body),
    };
    if (controller) {
      options.signal = controller.signal;
    }

    const res = await fetch(getUrl.toString(), options);

    const json = await res.json();

    if (res.ok) {
      return requestResponse(json);
    }
    return requestResponse(null, res.status, res.statusText, json);
  } catch (e) {
    requestResponse(null, e.status, e.statusText);
  }
}

async function post(url, body = null, queryParams = null, controller = null) {
  try {
    // console.log('we are posting body', body);
    return postPutDel(url, 'POST', body, queryParams, controller);
  } catch (e) {
    console.error(e);
    return e;
  }
}

async function put(url, body = null, queryParams = null, controller = null) {
  try {
    return postPutDel(url, 'PUT', body, queryParams, controller);
  } catch (e) {
    console.error(e);
    return e;
  }
}

async function del(url, body = null, queryParams = null, controller = null) {
  try {
    return postPutDel(url, 'DELETE', body, queryParams, controller);
  } catch (e) {
    console.error(e);
    return e;
  }
}

export { get, getBinaryAsBlob, post, put, del, standardServerError };

export default {
  get,
  getBinaryAsBlob,
  post,
  put,
  del,
};
