// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import authorizedComponentsList from './authorizedComponentsList';
import T from 'prop-types';
import { rightsRoutes, rightsRoutesWithChildren, routes } from '../list/dashboardRoutesList';

function filterRoutesFromRightMatrix(userType, allowedResources, selectedEntityRole) {
  let agentRoutes = [];

    const entityRoleToUse = selectedEntityRole || {};
    const { roles: userEntityRoles } = entityRoleToUse;

    agentRoutes = rightsRoutes(routes, null, (route) => {

      // console.log(' ffff', route.resources, allowedResources);
      // console.log(' qqqqq', route.entityRoles, userEntityRoles);

      return (
        (!route.resources || route.resources.some((r) => allowedResources.includes(r)))
        && (!route.entityRoles || route.entityRoles.some((r) => userEntityRoles.includes(r)))
      )
      }
    );

  // console.log('agentRoutes', routes, allowedResources, userEntityRoles);


  return _.uniqBy([...agentRoutes], 'path');
}

function filterRoutesFromRightMatrixWithChildren(userType, allowedResources, selectedEntityRole) {

  let agentRoutes = [];

    const entityRoleToUse = selectedEntityRole || {};
    const { roles: userEntityRoles, entityServices } = entityRoleToUse;

    agentRoutes = rightsRoutesWithChildren(routes, null, (route) =>
      (!route.resources || route.resources.some((r) => allowedResources.includes(r)))
      && (!route.entityRoles || route.entityRoles.some((r) => userType !== 'agent' || userEntityRoles.includes(r)))
      && (!route.entityServices || route.entityServices.some((r) => userType !== 'agent' || entityServices.includes(r)))
    )

  return _.uniqBy([...agentRoutes], 'path');
}

function canAccessComponent(componentName, allowedResources, userEntityRoles = []) {
  const foundDefinition = authorizedComponentsList.find((compo) => compo.name === componentName);

  if (!foundDefinition) {
    return false;
  }

  if (userEntityRoles && userEntityRoles.length) {
    return (
      (!foundDefinition.resources || foundDefinition.resources.some((r) => allowedResources.includes(r)))
      && (!foundDefinition.entityRoles || foundDefinition.entityRoles.some((r) => userEntityRoles.includes(r)))
    );
  }

  return (!foundDefinition.resources || foundDefinition.resources.some((r) => allowedResources.includes(r)));
}

// this checks the rights and show the child component or not
function ShowAuthorizedComponent({ allowedResources, userEntityRoles, userClientRoles, componentName, children }) {
  const allowedToAccess = canAccessComponent(componentName, allowedResources, userEntityRoles, userClientRoles);

  if (allowedToAccess) {
    return children;
  }
  return null;
}

ShowAuthorizedComponent.propTypes = {
  allowedResources: T.array.isRequired,
  userEntityRoles: T.array,
  componentName: T.string.isRequired,
  children: T.element.isRequired,
};

export { canAccessComponent, filterRoutesFromRightMatrix, filterRoutesFromRightMatrixWithChildren };
export default ShowAuthorizedComponent;
