// fetchData.js
import fetch from 'node-fetch';

const opt = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  // credentials: 'same-origin'
  credentials: 'include', // with CORS this is essential to use the set-cookie header response
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

async function fetchData(url, queryParams) {
  try {
    if (!url) {
      return null
    }

    const urlObj = new URL(url);
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, val])=> {
        urlObj.searchParams.append(key, val);
      })
    }

    const response = await fetch(urlObj, opt);
    console.log('response',response);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error);
    return null;
  }
}

export default fetchData;
