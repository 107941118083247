import { Redirect, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import DashMenu from '../components/Layout/Container/DashMenu';
import Container from '@material-ui/core/Container';
import T from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RouteWithSubRoutes from './SubRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 0,
  },
  contentContainer: {
    backgroundColor: theme.palette.background.default,
    minHeight: `calc(100% - 100px - 8px)`,
    height: `calc(100vh - 100px - 8px)`,
    // height: 3000, // test only
    // top: theme.toolbarHeight
    marginTop: 100,
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function AuthorizedRoutes({ allowedRoutes, logged }) {
  const classes = useStyles();

  // console.log('logged',logged, allowedRoutes);
  if (!logged) {
    localStorage.setItem('redirectURL',window.location.pathname)
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DashMenu/>
      <Container maxWidth="xl">
        <main className={classes.contentContainer}>
          <Switch>
            {allowedRoutes.map((route, i) => {
              if (logged) {
                return (
                  <RouteWithSubRoutes
                    key={`subRoute${route.labelKey || ''}_${i}`}
                    {...route}
                  />
                );
              }
              return null;
            })}
          </Switch>
        </main>
      </Container>
    </div>
  );
}

AuthorizedRoutes.propTypes = {
  allowedRoutes: T.array, // this array should match with the .routes that is passed from subRoutes and firstLevelRoutesList // here we are in the renderer of the first lvl
  logged: T.string,
  userType: T.string,
};

AuthorizedRoutes.defaultProps = {
  logged: null,
  userType: null,
  allowedRoutes: [],
};

const mapStateToProps = ({
  session: {
    userType,
    logged,
    allowedRoutes
  },
}) => {
  return {
    userType,
    logged,
    allowedRoutes,
  };
};

export default connect(mapStateToProps)(AuthorizedRoutes);
