/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import MailIcon from '@material-ui/icons/Mail';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import FlagIcon from '@material-ui/icons/Flag';
import NeoLocaleChange from '../../../elements/NeoLocale/Components/NeoLocaleChange';
import TranslatedContent from '../../../elements/NeoLocale/Components/NeoTranslatedContent';

function LayoutSettings() {
  const [checked, setChecked] = useState(['push']);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  return (
    <List subheader={<ListSubheader className='specialListHeader'>
      <TranslatedContent localKey={'layoutSettingsTitle'}/>
    </ListSubheader>}>

      {/* <ListItem>*/}
      {/*  <ListItemIcon>*/}
      {/*    <NotificationsActiveIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText id="switch-list-label-push" primary={<TranslatedContent localKey={'pushNotification'}/>} />*/}
      {/*  <ListItemSecondaryAction>*/}
      {/*    <Switch*/}
      {/*      edge="end"*/}
      {/*      onChange={handleToggle('push')}*/}
      {/*      checked={checked.indexOf('push') !== -1}*/}
      {/*      inputProps={{ 'aria-labelledby': 'switch-list-label-push' }}*/}
      {/*    />*/}
      {/*  </ListItemSecondaryAction>*/}
      {/* </ListItem>*/}
      {/* <ListItem>*/}
      {/*  <ListItemIcon>*/}
      {/*    <MailIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText id="switch-list-label-mail" primary={<TranslatedContent localKey={'mailNotification'}/>} />*/}
      {/*  <ListItemSecondaryAction>*/}
      {/*    <Switch*/}
      {/*      edge="end"*/}
      {/*      onChange={handleToggle('mail')}*/}
      {/*      checked={checked.indexOf('mail') !== -1}*/}
      {/*      inputProps={{ 'aria-labelledby': 'switch-list-label-mail' }}*/}
      {/*    />*/}
      {/*  </ListItemSecondaryAction>*/}
      {/* </ListItem>*/}

      <ListItem>
        <ListItemIcon>
          <FlagIcon />
        </ListItemIcon>
        <ListItemText id="locale-selector" primary={<TranslatedContent localKey={'language'}/>} />
        <ListItemSecondaryAction>
          <NeoLocaleChange/>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export default LayoutSettings;
