import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/reducers/layoutConfigReducer';
import { deleteUser } from '../../redux/reducers/sessionReducer';

/*
  handles the msg
    2 ways to call

  const [setMessage] = useMessage();

  // useMessage({ content: 'hello ', messageType: 'success' }); // fn
  // setMessage({ content: 'how are u', messageType: 'success' }); // hook
 */

export default (initMsg) => {
  const [message, setMessage] = useState(initMsg || null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!message) {
      return;
    }

    const {
      content, messageType, status,
      failed, errorInfos, disconnect, locked
    } = message;

    // disconnect is added by request response handler, most of the nodes will check on res.failed and simply pass the res to this function, so we can check here disconnect.

    let contentToUse = {content: 'Server error.', isSystemError: false};
    if (content) {
      contentToUse.content = content;
      if (messageType) {
        contentToUse.messageType = messageType;
      }
    }
    else if (failed || errorInfos) {
      // the message is an error from api
      contentToUse.content = `${errorInfos.error} ${errorInfos.dataKey || ''}`;
      contentToUse.isSystemError = true;
      if (!messageType) {
        contentToUse.messageType = 'error';
      }
    }
    else if (typeof message === 'string') {
      // the message is an error thrown or string directly passed
      contentToUse.content = message;
    }
    else if (message instanceof Error) {
      contentToUse.isSystemError = true;
      contentToUse.systemError = message;
    }
    // todo put this in the api error handler
    if (disconnect) {
      // this means the server has sent a http code : 401
      // we will destroy the current session, redirect to Login and show a message
      contentToUse.content = 'You have been disconnected for security reasons. Please log in.';
      contentToUse.messageType = 'warning';
      dispatch(deleteUser());
    }
    if (locked) {
      // this means the server has sent a http code 423 and entity needs validation
      // dispatch(deleteUser());
      window.location.replace('/sp/error?msg=agentNeedsApprovalFromRcbe');
    }
    if (status === 412) {
      window.location.replace('/sp/completeEntity');
    }
    if (status === 424) {
      window.location.replace('/sp/completeEntity?nameAgent=true');
    }

    dispatch(showMessage(contentToUse));

  }, [message]);


  return [setMessage];
};
