import { createTheme } from '@material-ui/core/styles';

const primaryColor = {
  main: '#f29400',
  light: '#ffa312',
  dark: '#b97200',

  contrastText: '#fff',
};

// shared styles elements between light and dark theme
export default createTheme({
  palette: {
    common: {
      black: '#262628',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.6)',
    },
    primary: primaryColor,
    secondary: {
      main: '#00983A',

      light: '#02af42',
      dark: '#01792e',
      contrastText: '#fff',
      gradient: 'radial-gradient(circle, #6affc2 0%, #55ffba 27.5%, #45ffb3 40%, #45ffb3 57.5%, #5bf9b8 75%, #5bf9b8 100%)',
    },
    tertiary: {
      dark: '#7579a0',
      main: '#a0a3c2',
      light: '#b0b3db',
      contrastText: '#ffffff',
    },
    quaternary: {
      ultraLight: '#168be5',
      light: '#1783d6',
      main: '#1176c6',
      dark: '#0c5ea0',
      contrastText: '#fdfdfd',
    },
    error: {
      dark: '#970404',
      main: '#bc0000',
      light: '#c60505',
      contrastText: '#ffffff',
    },
    red: {
      dark: '#ff3838',
      main: '#FA4B4B',
      light: '#fc5555',
      contrastText: '#ffffff',
    },
  },
  // turn off ripple
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});
