import { createStore, combineReducers } from 'redux';
import layoutConfigReducer from '../reducers/layoutConfigReducer';
import sessionReducer from '../reducers/sessionReducer';
// import declarationReducer from './declarationReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  layoutConfig: layoutConfigReducer,
  // declaration: declarationReducer,
});

const store = createStore(rootReducer);

export default store;
