import React from 'react';
import _ from 'lodash';

import MoneyIcon from '@material-ui/icons/Money';
import HomeIcon from '@material-ui/icons/Home';
import { Redirect } from 'react-router-dom';
import NoMatch from '../../elements/NeoAlerts/ErrorPage';
import DeclarationList from '../../pages/agent/DeclarationsList';
import DeclarationDetail from '../../pages/agent/DeclarationDetail';
import TaxPayersList from '../../pages/agent/TaxPayersList';
import TaxPayersSynthesis from '../../pages/agent/TaxPayersSynthesis';
import TaxPayersSynthesisGraph from '../../pages/agent/TaxPayersSynthesisGraph';
import EntitiesList from '../../pages/agent/EntitiesList';

const redirectToSp = () => <Redirect to="/sp/home" />;
const redirectToDeclarationList = () => <Redirect to="/sp/declaration/list" />;
const redirectToTaxPayersList = () => <Redirect to="/sp/taxPayers/list" />;



/*
consult_all_RCBE_declaration
consult_one_RCBE_declaration
validate_one_RCBE_declaration
consult_one_RCBE_synthesis
consult_all_taxpayers
consult_one_taxPayer_ecosynthesis
 */

const routes = [
  // HOME
  {
    exact: true,
    path: '/sp',
    // eslint-disable-next-line no-use-before-define
    component: redirectToSp,
    hideDashboard: true,
  },

  // ERROR
  {
    path: '/sp/error',
    pathnameLabel: '/sp/error',
    exact: false,
    component: () => <p>Error</p>,
    hideDashboard: true, // remove from home
    color: '#000',
  },

  // ACCUEIL
  {
    path: '/sp/home',
    pathnameLabel: '/sp/home',
    component: () => <div>Home</div>,
    labelKey: 'homeSection',
    icon: <HomeIcon />,
    hideDashboard: true,
  },

  // Declaration
  {
    path: '/sp/declaration',
    exact: true,
    component: redirectToDeclarationList,
    icon: <MoneyIcon />,
    color: '#235fc1',
    labelKey: 'declarationSection',
    resources: ['declaration'],
    entityRoles: ['consult_all_RCBE_declaration'],
    children: [
      {
        path: '/list',
        component: DeclarationList,
        labelKey: 'Liste des declarations',
      },
      {
        path: '/entitiesList',
        component: EntitiesList,
        labelKey: 'Liste des entités',
      },
      {
        // path: '/detail/:declarationId',
        path: '/detail',
        component: DeclarationDetail,
      },
    ],
  },

  // taxpayers and ecosynthesis
  {
    path: '/sp/taxPayers',
    exact: true,
    component: redirectToTaxPayersList,
    icon: <MoneyIcon />,
    color: '#235fc1',
    labelKey: 'taxPayersSection',
    resources: ['taxPayers', 'ecosynthesis'],
    entityRoles: ['consult_all_RCBE_declaration'], // todo specific claim
    children: [
      {
        path: '/list',
        component: TaxPayersList,
      },
      {
        // path: '/detail/:declarationId',
        path: '/detail',
        component: TaxPayersSynthesis,
      },
      {
        // path: '/detail/:declarationId',
        path: '/detailGraph',
        component: TaxPayersSynthesisGraph,
      },
    ],
  },

  // NO MATCH
  {
    path: '/sp/*',
    component: NoMatch,
  },
];

const rightsRoutesWithChildren = (routesList, parent, rightTest) => {
  let routes_final = []

  // On parcourt les routes
  for (let route of routesList) {

    let rtmp = _.cloneDeep(route)

    // On teste s'il y a un parent
    if (parent) {
      rtmp.path = parent.path + rtmp.path;

      rtmp.parent = parent.path;
      rtmp.pathnameLabel = rtmp.pathnameLabel ?? rtmp.path
      rtmp.resources = rtmp.resources ?? parent.resources
      rtmp.entityRoles = rtmp.entityRoles ?? parent.entityRoles
      rtmp.entityServices = rtmp.entityServices ?? parent.entityServices
    }

    // On teste si la route a des childrens
    if (rtmp.children) {
      rtmp.children = rightsRoutesWithChildren(rtmp.children, rtmp, rightTest)
    }

    // On push la route
    if (rightTest && rightTest(rtmp)) {
      routes_final.push(rtmp)
    }
  }

  return routes_final
}

const rightsRoutes = (routesList, parent, rightTest) => {
  let routes_final = []

  // On parcourt les routes
  for (let route of routesList) {

    let rtmp = _.cloneDeep(route)

    // On teste s'il y a un parent
    if (parent) {
      rtmp.path = parent.path + rtmp.path;

      rtmp.parent = parent.path;
      rtmp.pathnameLabel = rtmp.pathnameLabel ?? rtmp.path
      rtmp.resources = rtmp.resources ?? parent.resources
      rtmp.entityRoles = rtmp.entityRoles ?? parent.entityRoles
      rtmp.entityServices = rtmp.entityServices ?? parent.entityServices
    }

    // On teste si la route a des childrens
    if (rtmp.children) {
      routes_final = routes_final.concat(rightsRoutes(rtmp.children, rtmp, rightTest))
      delete (rtmp.children);
    }

    // On push la route
    if (rightTest && rightTest(rtmp)) {
      routes_final.push(rtmp)
    }
  }

  return routes_final
}

export {
  rightsRoutes,
  rightsRoutesWithChildren,
  routes
};
