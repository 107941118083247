/* eslint-disable react/display-name */
import React, { memo, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { NeoTableButton } from './NeoTableButton';
// import Link from '@material-ui/core/Link';

// for str and num // todo same cmp ?
function descendingComparator(a, b) {
  const aVal = a || 'z';
  const bVal = b || 'z';

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

function dateDescendingComparator(a, b) {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line no-unused-vars
function getComparator(order, orderBy, orderType = 'str') {
  if (!orderBy) {
    return null;
  }

  if (orderType === 'date') {
    return order === 'desc' ? (a, b) => dateDescendingComparator(_.get(a, orderBy), _.get(b, orderBy)) : (a, b) => -dateDescendingComparator(_.get(a, orderBy), _.get(b, orderBy));
  }

  return order === 'desc' ? (a, b) => descendingComparator(_.get(a, orderBy), _.get(b, orderBy)) : (a, b) => -descendingComparator(_.get(a, orderBy), _.get(b, orderBy));
}

function stableSort(array, comparator) {
  if (!comparator) {
    return array;
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const elemA = a[0] || 'false';
    const elemB = b[0] || 'false';

    return comparator(elemA, elemB);
    // if (order !== 0) {return order;}
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
const StyledTableHeaderCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.primary.main,
    // backgroundColor: '#f8f8f8',
    fontSize: 14,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

function EnhancedTableHead({ classes, order, orderBy, onRequestSort, columns }) {
  const createSortHandler =
    (property, usedOrderType = 'str') =>
      (event) => {
        onRequestSort(event, property, usedOrderType);
      };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, index) => {
          if (!headCell) {
            return null;
          }
          const title = <span>{headCell.label}</span>;

          const borderStyle = { borderTop: '1px solid rgba(0, 0, 0, 0.12)' };
          if (index === 0) {
            borderStyle.borderLeft = '1px solid rgba(0, 0, 0, 0.12)';
          } else if (index === columns.length - 1) {
            borderStyle.borderRight = '1px solid rgba(0, 0, 0, 0.12)';
          }

          return (
            <StyledTableHeaderCell
              key={headCell.keyRef || headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              // padding={headCell.disablePadding ? 'none' : 'default'}
              style={{
                minWidth: headCell.minWidth,
                maxWidth: headCell.maxWidth,
                width: headCell.width,
                ...borderStyle,
              }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sortable !== false ? (
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id, headCell.orderType)}>
                  {title}
                  {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
                </TableSortLabel>
              ) : (
                title
              )}
            </StyledTableHeaderCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
};

EnhancedTableHead.defaultProps = {
  orderBy: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  searchContainer: {
    margin: 15,
  },
  container: {
    // maxHeight: 440,
    maxHeight: 'calc(100vh - 180px)',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  smallTableCell: {
    padding: '4px 10px;'
  },
}));

const NeoTable = memo(
  ({
    dense = false,
    maxHeight = null,
    handleEditCell = null,
    columns,
    rows,
    setPage = null,
    ...props
  }) => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);
    const [orderType, setOrderType] = React.useState(null);

    const handleRequestSort = useCallback(
      (event, property, usedOrderType = 'str') => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOrderType(usedOrderType);
      },
      [order, orderBy]
    );

    return (
      <>
        <Paper elevation={0}>
            <MemoizedTable
              columns={columns}
              order={order}
              orderBy={orderBy}
              orderType={orderType}
              rows={rows}
              handleRequestSort={handleRequestSort}
              dense={dense}
              maxHeight={maxHeight}
              handleEditCell={handleEditCell}
              {...props}
            />
        </Paper>
      </>
    );
  }
);

const MemoizedTable = memo(({ maxHeight, dense, columns, order, orderBy, orderType, rows, handleRequestSort, ...props }) => {
  const classes = useStyles();


  const formatedRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy, orderType));
  }, [rows, order, orderBy, orderType]);

  if (!formatedRows || !formatedRows.length) {
    return null;
  }

  return (
    <TableContainer className={classes.container} style={maxHeight ? { maxHeight: maxHeight } : null}>
      <Table stickyHeader size={dense === true ? 'small' : 'medium'}>
        <EnhancedTableHead columns={columns} classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {formatedRows.map((row, index) => (
            <MemoizedTableRow key={`${row._id}_row_${index}`} row={row} columns={columns} dense={dense} {...props} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

const MemoizedTableRow = memo(({ row, columns, handleEditCell, dense, ...props }) => {
  const [refreshKey, setRefreshKey] = useState(1);
  const classes = useStyles();

  return (
    <TableRow tabIndex={-1}>
      {columns.map((column, colIndex) => {
        if (!column) {
          return null;
        }
        const valueBase = row[column.id] || _.get(row, column.id);
        const value = typeof column.format === 'function' ? column.format(valueBase) : valueBase;

        const Cell = (() => {
          switch (true) {
          case Boolean(column.buttons):
            return column.buttons.map((btn, btnindex) => (
              <NeoTableButton
                variant={btn.buttonVariant}
                hideButton={btn.showPrecondition ? !btn.showPrecondition(row) : false}
                hideOnSuccess={btn.hideOnSuccess}
                onClick={btn.onClick}
                onSuccess={
                  btn.onSuccess
                    ? (cb) => {
                      btn.onSuccess(row, cb);
                      setRefreshKey(refreshKey + 1);
                    }
                    : null
                }
                color={btn.buttonColor}
                style={{
                  ...btn.buttonStyle,
                  margin: '0 2px',
                  minWidth: 20,
                  height: '2.25rem',
                }}
                key={`${btn.linkTo}_${btnindex}_${refreshKey}`}
                valueBase={valueBase}
                buttonIcon={btn.buttonIcon}
                linkTo={btn.linkTo}
                linkParams={btn.linkParams || `?${btn.linkKey}=${row[btn.linkKey]}`}
                label={btn.buttonLabel}
              />
            ));
            case Boolean(column.renderer):
              return column.renderer({ ...row, ...props });
            default:
              return <p data-raw={valueBase}>{value}</p>;
          }
        })();

        return <TableCell classes={{ sizeSmall: classes.smallTableCell }} key={column.keyRef || column.id}>{Cell}</TableCell>;
      }).filter((elem) => elem)}
    </TableRow>
  );
});

NeoTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  noContentKey: PropTypes.string,

  pageNumber: PropTypes.number.isRequired, // current nb of page (first is 1)
  totalPages: PropTypes.number.isRequired, // total nb of pages
  totalItems: PropTypes.number.isRequired, // total nb of rows for all pages
  nbRows: PropTypes.number.isRequired, // current nb of rows for this page
  rowsPerPage: PropTypes.number.isRequired, // max rows per page
  setPage: PropTypes.func,
  setSearch: PropTypes.func,
  handleEditCell: PropTypes.func,
  dense: PropTypes.bool,
  maxHeight: PropTypes.string,
};

export default NeoTable;
