const serverUrl = process.env.REACT_APP_API_URL;

const endpoints = {
  declarationsList: `${serverUrl}/agents/declarations`,
  getOneDeclaration: (id) => `${serverUrl}/agents/declarations/${id}`,
  validateOneDeclaration: (id) => `${serverUrl}/agents/declarations/${id}/validate`,

  // RCBE: `${serverUrl}/agents/declarations`, // same as oneDeclaration right ??

  entitiesList: `${serverUrl}/agents/entitiesList`,
  taxPayersList: `${serverUrl}/agents/taxPayers`,
  ecoSynthesis: `${serverUrl}/agents/taxPayers/ecoSynthesis`, // ?physicalTaxPayerNIU=123121221212
  rcbePerEntity: (entityTaxPayerId) => `${serverUrl}/agents/taxPayers/${entityTaxPayerId}/rcbe`,
}

export { endpoints };

export default endpoints
