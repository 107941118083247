import { filterRoutesFromRightMatrix, filterRoutesFromRightMatrixWithChildren } from '../../routes/authorizedComponents/ShowAuthorizedComponent';
import { removeLayoutData } from './layoutConfigReducer';

const loggedUserStorage = localStorage.getItem('logged');

let foundUser;
// eslint-disable-next-line no-undefined
if (['undefined', 'null', 'false', undefined, null, false].includes(loggedUserStorage)) {
  foundUser = null;
} else {
  foundUser = loggedUserStorage;
}

// the user infos are stored locally on a crypted form, we get them when needed only, when not refreshed the redux has them in RAM
const getUserInfos = () => {
  const storedUserInfos = localStorage.getItem('UINFO');
  if (!foundUser || !storedUserInfos) {
    return {};
  }

  const initDecryptedPayload = JSON.parse(storedUserInfos);
  const initEntitiesRoles = initDecryptedPayload.entitiesRoles || [];
  const initSelectedEntityRole = (initDecryptedPayload.entitiesRoles && initDecryptedPayload.entitiesRoles.length > 0)
    ? (initEntitiesRoles.find((role) => (role.entity === localStorage.getItem('selectedEntityId'))) || initDecryptedPayload.entitiesRoles[0])
    : null;

  // console.log('initEntitiesRoles', initEntitiesRoles, initSelectedEntityRole, initDecryptedPayload);

  const initObj = {
    logged: foundUser || null,
    userEmail: initDecryptedPayload.email || '',
    userName: initDecryptedPayload.name || '',
    userType: initDecryptedPayload.type || null,
    allowedResources: initDecryptedPayload.allowedResources || [],
    entitiesRoles: initEntitiesRoles,
    selectedEntityRole: initSelectedEntityRole,
    userEntityRoles: initSelectedEntityRole ? initSelectedEntityRole.roles : [],
    allowedRoutes: [],
    allowedRoutesWithChildren: [],
  };

  initObj.allowedRoutesWithChildren = filterRoutesFromRightMatrixWithChildren(initObj.userType, initObj.allowedResources, initObj.selectedEntityRole);
  initObj.allowedRoutes = filterRoutesFromRightMatrix(initObj.userType, initObj.allowedResources, initObj.selectedEntityRole);

  // console.log('initObj.allowedRoutes', initObj.allowedRoutes);
  return initObj;
};

const initialState = getUserInfos();

// DISPATCHERS

export const setUser = ({
  payload
}) => (
  {
    type: 'LOGIN_SUCCESS',
    payload,
  }
);

export const deleteUser = () => ({ type: 'LOGOUT' });

function sessionReducer(state = initialState, action) {
  switch (action.type) {

    case 'LOGIN_SUCCESS': {
      // decrypt the payload and get allowedResources
      const parsedPayload = action.payload;
      const { id, allowedResources, entitiesRoles, type, subType, email, name } = parsedPayload;

      localStorage.setItem('UINFO', JSON.stringify(action.payload));
      localStorage.setItem('logged', id);

      const newSessionObj = {
        ...state,
        logged: id, // id user logged
        userType: type, // user type
        userSubType: subType,
        userEmail: email, // email user
        userName: name, // user full name
        allowedResources: allowedResources, // allowed resources for that user
        entitiesRoles, // arrat of the entities and roles this user has : user.entitiesRoles
        selectedEntityRole: (entitiesRoles && entitiesRoles.length > 0) ? entitiesRoles[0] : null, // current entity that has focus
        userEntityRoles: (entitiesRoles && entitiesRoles.length > 0) ? entitiesRoles[0].roles : [], // roles of the current entity that has focus
      };
      // generated list of routers allowed based on previous info
      newSessionObj.allowedRoutesWithChildren = filterRoutesFromRightMatrixWithChildren(newSessionObj.userType, newSessionObj.allowedResources, newSessionObj.selectedEntityRole);
      newSessionObj.allowedRoutes = filterRoutesFromRightMatrix(newSessionObj.userType, newSessionObj.allowedResources, newSessionObj.selectedEntityRole);
      console.log('newSessionObj', newSessionObj);
      return newSessionObj;
    }

    case 'LOGOUT': {
      localStorage.removeItem('logged');
      localStorage.removeItem('UINFO');
      removeLayoutData();
      return {
        ...state,
        logged: null,
        allowedResources: [],
        allowedRoutes: [],
        allowedRoutesWithChildren: [],
      };
    }

    default:
      return state;
  }
}

export default sessionReducer;
