import React, { useEffect, useState } from 'react';

import { Button, Grid, Paper, Typography, TextField } from '@material-ui/core';
import fetchData from '../../request/fetchData';
import endpoints from '../../request/endpoints';
import { useLocation } from 'react-router-dom';
import { post } from '../../request';
import NeoTable from '../../elements/NeoLayouts/NeoTable';
import { Info } from '@material-ui/icons';

const columns = [
  { label: 'Nom', id: 'entityBeneficiaryLastName' },
  { label: 'Prénom', id: 'entityBeneficiaryFirstName' },
  { label: 'NIU', id: 'entityBeneficiaryNIU' },
  { label: 'Pays de résidence', id: 'entityBeneficiaryCountry' },
  { label: 'Type de bénéficiaire', id: 'entityBeneficiaryType' },
  { label: '% actions directes', id: 'entityBeneficiaryDirectSharePercent' },
  { label: '% actions indirectes', id: 'entityBeneficiaryIndirectSharePercent' },
  {
    sortable: false,
    width: 300,
    id: '_id',
    label: 'Actions',
    buttons: [
      {
        buttonColor: 'secondary',
        buttonLabel: 'detail',
        buttonIcon: <Info />,
        linkTo: '/sp/taxPayers/detail', // here linkTo will trigger a button with router and id row passed as query params
        linkKey: 'entityBeneficiaryNIU'
      },
      {
        buttonColor: 'primary',
        buttonLabel: 'graph',
        buttonIcon: <Info />,
        linkTo: '/sp/taxPayers/detailGraph', // here linkTo will trigger a button with router and id row passed as query params
        linkKey: 'entityBeneficiaryNIU'
      },
    ],
  },
];


const DeclarationDetail = ({ }) => {
  const [data, setData] = useState(null);
  const location = useLocation();
  // let { id } = useParams();

  // Extraire les query params
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get('_id');
  const entityId = queryParams.get('entityId');

  async function validateDeclaration() {
    try {
      await post(endpoints.validateOneDeclaration(_id));
    } catch (e) {
      console.error(e);
    }
  }

  // fetchData
  useEffect(() => {
    if (_id){
      fetchData(endpoints.getOneDeclaration(_id)).then((ress) => setData(ress));
    }
  }, [_id]);

  useEffect(() => {
    if (entityId) {
      console.log('fetcccc', entityId);

      fetchData(endpoints.rcbePerEntity(entityId)).then((ress) => setData(ress));
    }
  }, [entityId]);


  if (!data) {
    return null;
  }

  return (
    <Grid container spacing={2}>

      {/* resp */}
      <Grid item xs={12}>
        <Typography variant={'h5'}>
          Recapitulatif des Informations detenues sur l&apos;entité
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Forme Juridique"
          variant="standard"
          defaultValue={data.entity.entityLegalType}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Raison Sociale"
          variant="standard"
          defaultValue={data.entity.entityName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Numéro Immatriculation"
          variant="standard"
          defaultValue={data.entity.entityNIU}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Secteur d'activité"
          variant="standard"
          defaultValue={data.entity.entityActivity}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Adresse Siège Sociale"
          variant="standard"
          defaultValue={data.entity.entityAddress}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Pays"
          variant="standard"
          defaultValue={data.entity.entityRespCountry}
        />
      </Grid>

      {/* resp */}
      <Grid item xs={12}>
      <br/><br/>
      <Typography variant={'h5'}>
        Informations sur le dirigeant social / representant
      </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Titre"
          variant="standard"
          defaultValue={data.entity.entityRespTitle}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Nom"
          variant="standard"
          defaultValue={data.entity.entityRespLastName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Prénom"
          variant="standard"
          defaultValue={data.entity.entityRespFirstName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Adresse de residence"
          variant="standard"
          defaultValue={data.entity.entityRespAddress}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Pays"
          variant="standard"
          defaultValue={data.entity.entityRespCountry}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Numéro CNI"
          variant="standard"
          defaultValue={data.entity.entityRespCNI}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Numéro PassePort"
          variant="standard"
          defaultValue={data.entity.entityRespPassport}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled
          fullWidth
          label="Pays émetteur Passeport"
          variant="standard"
          defaultValue={data.entity.entityRespPassportCountry}
        />
      </Grid>
      {/* <Grid item xs={6}> */}
      {/*   <TextField */}
      {/*     disabled */}
      {/*     fullWidth */}
      {/*     label="Date Expiration Passeport" */}
      {/*     variant="standard" */}
      {/*     defaultValue={data.entity.entityRespPassportExpirationDate} */}
      {/*     // type="date" */}
      {/*   /> */}
      {/* </Grid> */}

      {/* benef */}
      <Grid item xs={12}>
        <br/><br/>
        <Typography variant={'h5'}>
          Informations sur les bénéficiaires
        </Typography>
      </Grid>

      <NeoTable columns={columns} rows={data?.entityBeneficiaries || []}/>

      {data.status === 'draft'
        ? <Grid item xs={12}>
          <Button onClick={validateDeclaration} variant={'contained'} color={'primary'}>
            Valider déclaration
          </Button>
        </Grid>
        : null}

      </Grid>
  );
};

export default DeclarationDetail
